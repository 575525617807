import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Inicio from './Views/Inicio'

function App() {
  return (
        <Router>
            <Switch>
                <Route exact path="/">
                    <Inicio />
                </Route>
                <Route path="/">
                    <Inicio />
                </Route>
            </Switch>
        </Router>
    );
}

export default App;
