import React, { Fragment } from 'react'
import ReactAudioPlayer from 'react-audio-player'

const Inicio = () => {
    
    return (
    	<Fragment>
    	<ReactAudioPlayer
			  src="/assets/audio/song.mp3"
			  className="song"
			  autoPlay={false}
			  controls={false}
			/>
			
    		<div className="wrap" id="wrap">
				<div className="card card--inner" id="style-6">
					<div className="force-overflow"></div>
					<h2>Si quieres una tarjeta como esta por solo $50, manda whats al 449 187 58 17, te quiero mucho 😘</h2>
					<img src="assets/img/imagen.jpg" className="imagen-portada" />
				</div>
				<div className="card card--front back"></div>
				<div className="card card--front">
					<h2>Feliz Navidad<br/>2021</h2>
					<img src="assets/img/isotipo.png" className="logo" />
				</div>
			</div>

			<button type="button" id="button"><b id="color-boton">Abrir carta</b></button>
	    </Fragment>
    )
}

export default Inicio;